@import "../../../theme.scss";

input.textInput {
  display: block;
  width: 100%;
  font-size: $font-size-medium;
  border: 1px solid $color-grey60;
  outline: none;
  padding: 0 10px;
  height: 38px;

  &:hover,&:focus {
    border: 1px solid $color-blue;
  }

  &::placeholder {
    color: $color-grey50;
    opacity: 1;
  }

  &[disabled] {
    border: 1px solid $color-grey80;
    cursor: not-allowed;
  }
}

textarea.multiline {
  resize: none;
  padding: 15px;
  background: transparent;
  border: 1px solid $color-grey60;
  color: $color-black;
  width: 100%;
  height: 90px;
  font-family: inherit;
  font-size:$font-size-medium;

  &:hover,&:focus {
    border: 1px solid $color-blue;
  }

  &::placeholder {
    color: $color-grey50;
  }
}

.multiline-black{
  background: $color-grey15;
  border: 1px solid $color-white;
  width: 100%;
  height: 135px;
  padding: 15px;
  font-size: $font-size-medium;
  font-family: inherit;
  resize: none;
}
