@import "../../../theme.scss";

.modal-window.about {
  width: 505px;

  .loading{width: 100%;}

  .modal-image-header.map {
    padding: 16px;
    background-image: url("../../../static/img/wide-vid-map.png");
    transition: none;

    svg {
      stroke: $color-black;
    }
  }

  .modal-header {
    font-size: $font-size-small;
    line-height: 125%;
    align-items: baseline;
    padding-bottom: 10px;

    h3 {
      font-size: $font-size-large;
      font-weight: $font-weight-medium;
      margin-bottom: 10px;
    }

    div p {
      margin-top: 0;
      margin-bottom:0;
    }

    a {
      color: $color-grey40;
      text-decoration: none;
      line-height: 150%;
      border: 1px solid $color-grey40;
      border-radius: 4px;
      padding: 5px 12px;

      svg {
        margin-left: 10px;
      }
    }

    .register{
      font-size: $font-size-small;
      position: absolute;
    }
  }

  .modal-body {
    font-size: $font-size-small;

    table.datasources {
      width: 100%;
      border-collapse: collapse;

      thead {

        tr { border-bottom: 1px solid $color-grey60; }

        th {
          text-align: left;
          color: $color-grey60;
          font-weight: $font-weight-medium;
          line-height: 150%;
          padding-bottom: 7px;
        }
      }

      tbody {

        .information-column{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
        }

        td {
          padding: 5px 0;
        }
      }
    }
  }
}
