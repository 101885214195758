@import "theme.scss";

.default-menu {

  li {

    &[role="menuitem"] {

      &:hover {
        background-color: rgba($color-blue, .25);
      }

      &:active {
        background-color: none;
        color: initial;
      }

      a{
        text-decoration: none;
      }
    }
  }
}

.sidebar-context-menu {

  ul{

    &[role="menu"] {border-radius: 0;}}

  li[role="menuitem"] {
    line-height: normal;
    font-size: $font-size-medium;
  }
}
