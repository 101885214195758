@import "../../../theme.scss";

.map {

  .region{
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: 0.4;
      width: 35px;
      max-height: 35px;
    }

    .label {
      display: none;
      font-weight: $font-weight-bold;
      position: absolute;
      width: 100px;
      right: -104px;
    }

    &:hover {

      .label {
        display: block;
      }
    }

    &.loading {

      img {
        opacity: 1;
      }
    }

    &.enabled {
      background: $color-white;
      border: 1px solid $color-grey70;
      box-sizing: border-box;
      box-shadow: 0px 1px 5px $color-grey70;
      border-radius: 100px;

      img {
        opacity: 1;
      }

      .label {
        display: block;
      }

      &:hover {
        border-color: $color-black;
      }

      &.selected {
        border-color: $color-serviceorange;
        box-shadow: 0px 1px 5px $color-serviceorange;
      }

      &.loading {
        opacity: 0.5;
      }
    }
  }

  .region-label {
    background: none;
    border: none;
    box-shadow: none;
    font-weight: $font-weight-bold;

    &:before {
      border: none;
    }
  }

}
