@import "../../../../../theme.scss";

.info-panel {

  .EditMetroView {

    h2 {
      display: flex;

      .checkbox-container {
        margin-left: 10px;
      }

      label {
        font-weight: $font-weight-normal;
        font-size: $font-size-large;
        line-height: 131%;
      }
    }

    .note {
      padding-top: 0;

      h3 {
        margin-top: 30px;
      }

      .input {
        position: relative;

        &.new-label {
          margin-top: 15px;

          input {
            background: transparent;
            border: 1px solid $color-grey60;
            border-radius: 2px;
            padding: 10px 15px;
            color: $color-black;
            width: 100%;
            font-family: inherit;
            font-size: $font-size-medium;
            line-height: 150%;
          }

          .loading {
            top: 6px;
            right: -8px;
          }
        }

        .loading {
          position: absolute;
          right: -9px;
          top: 6px;
        }
      }

      .labels {
        background: $color-white;
        box-shadow: 0px 0px 5px rgba($color-black, 0.2);
        border-radius: 2px;
        margin: 15px 0;

        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px;
          border-bottom: 1px solid rgba($color-black, 0.1);

          .input {
            width: 50%;

            input {
              width: 100%;
              border: 1px solid $color-grey70;
              border-radius: 2px;
              padding: 7px 9px;
              font-size: $font-size-small;
            }
          }

          .color {
            width: 14px;
            height: 14px;
            opacity: 0.7;
            border-radius: 100px;
            cursor: pointer;

            &:hover {
              box-shadow: 1px 1px 5px 1px rgba($color-black, 0.3);
            }

            &.active {
              border: 2px solid $color-black;
            }
          }

          .delete.loading {
            margin-right: 0;
          }
        }
      }
    }

    td.direct-connection {
      overflow: visible;
    }

    .direct-connection-form {
      display: flex;
      align-items: center;
    }

    .warning-message {
      padding: 9px 11px;
      background: $color-lightorange;
      border-radius: 4px;
      margin-bottom: 30px;
      font-size: $font-size-medium;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        overflow: visible;
        margin-right: 9px;
      }
    }
  }

  .content-view {
    padding: 0;

    &.tab-panel {
      margin: 12px 0;
    }
    /* INFO PANEL FORM ELEMENTS */

    .content-section {
      border-top: 1px solid $color-grey70;
      padding: 30px;

      &:first-child {
        border-top: none;
      }

      p {
        font-size: $font-size-medium;
        line-height: 143%;

        &.instructions {
          margin-top: 0;
          margin-bottom: 30px;
        }

        sup {
          font-size: $font-size-very-small;
        }
      }

      label,
      h4 {
        display: block;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin: 0 0 10px;
      }

      textarea {
        height: 120px;
        padding: 10px 10px;
        line-height: 125%;
      }

      table {
        width: 100%;
        font-size: $font-size-medium;
        font-weight: $font-weight-light;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      table.services {

        svg {
          width: 6px;
          height: 6px;
        }

        tr {

          th,
          td {
            max-width: 220px;
          }

          td {

            .loading {
              margin-right: 0;
              width: 18px;
              height: 18px;
            }

            .delete-row,
            .toggle-visibility-row {
              border: none;
              outline: none;
              cursor: pointer;
              opacity: 0.7;

              &:hover {
                opacity: 1;
              }
            }
          }

          td:first-child {
            display: flex;
            gap: 5px;
            align-items: center;

            div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          td:nth-last-child(2),
          td:last-child {
            position: relative;
            min-width: 54px;
            // min-height: 44px;
            text-align: right;

            &.empty {
              text-align: left;
            }

            .delete-row {
              transform: translateX(-20%);
            }
          }
        }
      }

      .status {
        position: relative;
        float: right;
        margin-right: 19px;
        line-height: 556%;
        font-size: $font-size-very-small;
        text-align: right;
        letter-spacing: 1px;
        text-transform: uppercase;

        &.active {
          padding-right: 20px;
          background: url("../../../../../static/img/use-case-off.svg") center right no-repeat;
        }

        &.active.on {
          color: $color-green;
          background: url("../../../../../static/img/use-case-on.svg") center right no-repeat;
        }
      }

      .activate-switch {
        position: relative;
        float: right;
        width: 35px;
        height: 20px;
        background-color: $color-grey60;
        border: none;
        outline: none;
        border-radius: 9999px;
        cursor: pointer;

        &:hover {
          background-color: $color-grey40;
        }

        .switch-knob {
          position: absolute;
          top: 3px;
          left: 3px;
          width: 14px;
          height: 14px;
          background-color: $color-white;
          border-radius: 9999px;
        }

        &.on {
          background-color: $color-red;

          .switch-knob {
            left: 18px;
          }
        }
      }

      .use-case {
        margin-bottom: 10px;
        background: $color-grey80;
        color: $color-grey60;
        border-radius: 2px;

        p {
          font-size: $font-size-medium;
          color: $color-grey40;
        }

        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          padding: 0;
          font-weight: $font-weight-normal;
        }

        h3 {
          font-size: $font-size-large;
          font-weight: $font-weight-bold;
          line-height: 313%;
          padding-left: 40px;
        }

        h4 {
          font-size: $font-size-medium;
          line-height: 172%;
          padding-left: 20px;
          color: $color-black;
        }

        h5 {
          font-size: $font-size-very-small;
          text-transform: uppercase;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        h6 {
          font-size: $font-size-small;
          line-height: 185%;
          padding-left: 20px;
          color: $color-black;
        }

        button.expand-content {
          outline: none;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          background-position: 4px center;
          background-repeat: no-repeat;
        }

        ul,
        li {
          margin: 0;
          padding: 0;
          list-style: none;
          list-style-type: none;
        }

        .use-case-header {
          display: flex;
          justify-content: flex-start;

          .expand-content {
            background-position: 18px center;
          }

          .use-case-controls {
            padding: 15px;
            font-size: $font-size-small;
            line-height: 167%;
            color: $color-grey60;
          }
        }

        .use-case-content {
          padding: 10px 0 20px;
        }

        .expandable-header {
          display: flex;
          justify-content: flex-start;

          .ioakb-link {
            margin-left: 5px;
            width: 20px;
            padding: 5px;
            background: url("../../../../../static/img/icon-external-link.svg") center center no-repeat;
            color: transparent;
          }
        }

        .expandable-content {
          padding-left: 20px;

          &.use-case-content {
            padding-left: 40px;
            padding-right: 40px;
            border-top: 1px solid $color-grey70;
          }

          &.design-pattern-content {
            padding-bottom: 20px;
          }
        }

        &.active {
          color: $color-grey40;
          background: $color-white;
          box-shadow: 0px 0px 10px rgba($color-black, 0.25);
        }
      }

      .expandable {

        &.open {
          //background-color: rgba(255,0,0,.1); // DEBUGGIN'

          .expandable-content {
            display: block;
          }

          .expandable-header {
            //background-color: rgba(255,0,0,.1); // DEBUGGIN'

            .expand-content {
              background-image: url("../../../../../static/img/icon-minus.svg");
            }
          }
        }

        &.closed {

          .expandable-content {
            display: none;
          }

          .expandable-header {
            //background-color: rgba(255,0,0,.1); // DEBUGGIN'

            .expand-content {
              background-image: url("../../../../../static/img/icon-plus.svg");
            }
          }
        }

        .use-case-header {
          justify-content: space-between;
        }
      }
    }

    .platform-equinix-section {
      border-bottom: 1px solid $color-grey70;

      &:last-child {
        border-bottom: none;
      }
      padding: 4px 20px;

      .platform-equinix {
        border-bottom: 1px solid $color-grey70;

        &:last-child {
          border-bottom: none;
        }

        &.open {
          border-bottom: none;

          .expandable-content {
            display: block;
          }

          .platform-equinix-header {

            .open-icon {

              svg {
                transform: rotate(0deg);
              }
            }

            &.category {

              svg {
                transform: rotate(0deg);
              }
            }
          }
        }

        &.closed {

          .expandable-content {
            display: none;
          }

          .deployment-wrapper {
            margin: 0;
          }

          .platform-equinix-header {

            &.category {

              svg {
                transform: rotate(270deg);
              }
            }
          }
        }

        button.expand-content {
          outline: none;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          background-position: 4px center;
          background-repeat: no-repeat;
        }

        .platform-equinix-header {

          &.category {
            padding: 6px 0;

            .expand-content {

              h3 {
                padding: 0;
                font-size: $font-size-medium;
                line-height: 117%;
                font-weight: $font-weight-normal;
              }
            }

            .toggle-button {
              margin-right: 14px;
            }
          }
          padding: 0 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon-div {
            display: flex;
          }

          span {
            color: $color-grey60;
            font-style: italic;
            font-size: $font-size-medium;
            line-height: 130%;
          }

          .productsText {
            padding: 0 20px;
          }

          .marketplace-link {

            span {
              font-size: $font-size-small;
              font-style: normal;
              font-weight: $font-weight-normal;
              line-height: 133%;
              letter-spacing: 0px;
              color: $color-black;
              margin-right: 8px;
            }
          }

          .open-icon {
            display: flex;
            cursor: pointer;

            svg {
              width: 25px;
              visibility: visible;
              overflow: visible;
              transform: rotate(270deg);
            }
          }

          .expand-content {

            h3 {
              font-size: $font-size-large;
              font-weight: $font-weight-bold;
              line-height: 313%;
              padding-left: 10px;
              margin: 0;

              &.no-icon-title {
                padding-left: 0;
              }
            }
          }

          .spinner {
            display: inline-block;
            vertical-align: sub;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background: url("../../../../../static/img/icon-progress.svg") center center no-repeat;
            animation: spin 0.6s infinite linear;
            @keyframes spin {

              100% {
                -moz-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }

          .spacer {
            flex-grow: 1;
          }
        }

        .category-items {

          .content-section {
            padding: 0;
          }
        }

        .equinix-platform-panel {
          padding: 0 10px;

          .marketplace-content {
            display: flex;
            gap: 20px;
          }

          .marketplace-category {
            width: 100%;

            h4 {
              font-size: $font-size-medium;
              line-height: 130%;
              font-weight: $font-weight-bold;
              color: $color-grey20;
              padding-bottom: 5.5px;
              border-bottom: solid $color-grey20 1px;
            }
          }

          .marketplace-items-wrapper {
            display: flex;
            flex-direction: column;
          }

          .marketplace-item {
            display: flex;
            flex-direction: column;
            margin: 6px 0;

            .title {
              display: flex;
              align-items: center;
              font-size: $font-size-medium;
              line-height: 130%;
              font-weight: $font-weight-normal;
              margin-left: 52px;
              margin-bottom: 5px;
              color: $color-grey20;

              &.expandable {

                span {
                  cursor: pointer;
                }
              }
            }

            .open-icon {
              display: flex;
              cursor: pointer;
              padding: 0 4px;

              svg {
                transform: rotate(270deg);
              }

              &.open {

                svg {
                  transform: rotate(0deg);
                }
              }
            }

            .bar-wrapper {
              display: flex;
              align-items: center;

              .bar {
                height: 11px;
                border-radius: 8px;
                background-color: $color-blue;
              }

              .value {
                text-align: end;
                width: 44px;
                font-size: $font-size-medium;
                font-weight: $font-weight-bold;
                line-height: 129%;
                margin-right: 8px;
              }

              &.avg {

                .bar {
                  height: 6px;
                  background-color: $color-grey50;
                  opacity: 0.25;
                }

                .value {
                  font-size: $font-size-small;
                  font-weight: $font-weight-normal;
                  line-height: 130%;
                  color: $color-grey50;
                }
              }
            }

            &.child {

              .title {
                font-size: $font-size-small;
                line-height:130%;
                color: $color-grey50;
                margin-bottom: 0;
              }

              .bar {
                height: 6px;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    &.hidden {
      display: none;
    }
  }
}

.product-wrapper {
  box-shadow: 0px 0px 5px rgba($color-black, 0.2);
  margin: 8px 0 18px;
}

.edge-wrapper {
  margin-bottom: 10px;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  margin: 0;
  line-height: 212%;
  background: $color-white;
  border-radius: 2px;
  border-bottom: 1px solid rgba($color-black, 0.1);

  span {
    font-size: $font-size-medium;
  }

  .loading {
    margin-right: 0;
    width: 18px;
    height: 18px;
  }
}
