@import "../../../../../theme.scss";

.asp-info-wrapper {
  display: grid;
  grid-template-rows: 70px auto 70px;
  height: 100%;
  overflow: auto;

  .companies {
    -webkit-tap-highlight-color: transparent;
    overflow-y: auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    grid-row-start: 2;
    grid-row-end: -1;
  }

  .title {
    display: flex;
    grid-row: 1;
    margin: 0;
    padding: 25px 0 26px 30px;
    padding-right: 60px;
    font-size: $font-size-heading-medium;
    line-height: 100%;
    font-weight: $font-weight-bold;
    color: $color-black;
    border: none;
    border-bottom: 1px solid rgba($color-black, 0.2);
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company-wrapper {
    padding: 15px 30px 35px 30px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba($color-black, 0.2);

    &.last {
      border-bottom: none;
    }
  }

  .company-name {
    font-size: $font-size-heading-small;
    border-bottom: 1px solid $color-black;
    padding-bottom: 15px;
  }

  .info-section {
    display: flex;
    margin-bottom: 14px;
    position: relative;
  }

  dt,
  dd,
  h3 {
    font-size: $font-size-small;
  }

  dt,
  .it-spend h3 {
    color: $color-grey30;
  }

  dt {
    display: flex;
    flex-basis: 45%;
  }

  dd {
    font-weight: $font-weight-bold;
  }

  .tag-wrapper {
    display: flex;
    flex-wrap: wrap;

    p {
      color: $color-grey30;
      font-size: $font-size-small;
      margin: 0;
    }
  }

  .tag {
    background: $color-white;
    font-size: $font-size-small;
    border: 1px solid rgba($color-grey60, 0.3);
    border-radius: 5px;
    padding: 6px 8px;
    margin: 5px 5px 5px 0;
  }

  .it-spend {
    position: relative;
  }
}

.separator {
  padding-bottom: 15px;

  &:after {
    content: "";
    border-bottom: 1px solid $color-grey60;
    opacity: 0.3;
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}
