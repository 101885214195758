@import "../../../../theme.scss";

.AddLocationView {
  display: grid;
  grid-template-rows: 70px min-content min-content min-content auto 70px;
  height: 100%;

  >input {
    grid-row: 1;
    padding: 30px;
    padding-right: 70px;
    font-size: $font-size-heading-medium;
    font-weight: $font-weight-bold;
    font-family: "Roboto", helvetica, sans-serif;
    color: $color-black;
    border: none;
    border-bottom: 1px solid rgba($color-black, 0.2);
    background: transparent;
  }

  >input::placeholder {
    opacity: 0.2;
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin: 0;
    margin-bottom: 30px;
    font-size: $font-size-medium;
    color: $color-grey20;
  }

  .type {
    grid-row: 2;
    padding: 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);

    .LocationTypeList {
      margin: 0;
      margin-top: 20px;
      padding: 0;
      list-style: none;
      display: flex;
      gap: 10px;

      li {

        .lightGray{border: 1px solid $color-grey60};
      }
    }
  }

  .where {
    overflow: visible;
    grid-row: 3;
    padding: 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);

    input {
      padding: 15px;
      background: transparent;
      border: 1px solid $color-grey60;
      border-radius: 2px;
      color: $color-black;
      width: 100%;
      font-size:$font-size-medium;
      line-height: 150%;
    }

    input::placeholder {
      color: $color-grey60;
    }

    .dropdown {
      position: relative;

      .dropdown-choices {
        position: absolute;
        background: $color-white;
        width: 100%;
        border: 1px solid $color-grey60;
        box-shadow: 0 2px 2px 0 rgba($color-black, 0.3);
        padding: 10px 0;

        .dropdown-choice {
          font-size:$font-size-medium;
          padding: 5px 15px;
          cursor: pointer;

          &:hover {
            background: $color-waterblue;
          }
        }
      }
    }
  }

  .employees {
    grid-row: 4;
    display: grid;
    grid-template-columns: auto min-content;
    padding: 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);
    font-size: $font-size-medium;
    line-height: 100%;

    h3 {
      grid-row: 1;
      grid-column: 1;
    }

    .input {

      input {
        font-size: $font-size-medium;
        margin-top: 15px;
        margin-right: 10px;
        width: 70px;
        padding: 13px;
        border: 1px solid $color-grey60;

        &.error {
          border: 1px solid $color-red;
        }
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      grid-row: 2;
      grid-column: 1;
    }

    .buttons-legend {
      grid-row: 1;
      grid-column: 2;
    }

    .size-buttons {
      grid-row: 2;
      grid-column: 2;
      display: flex;
      margin-top: 15px;
      gap: 6px;

      .lightGray{
        border: 1px solid $color-grey60;
      }

      button {
        cursor: pointer;
        padding: 0;
        width: 40px;
        height: 40px;
      }
    }
  }

  .note {
    grid-row: 5;
    padding: 30px;
  }

  .buttons {
    grid-row: 6;
    width: 100%;
  }
}
