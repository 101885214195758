@import "../../theme.scss";

.button {
  border: none;
  cursor: pointer;
  padding: 14px 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  span {
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
    line-height: 113%;
    color: $color-white;
  }

  .smallerText {
    line-height: 100%;
    font-size: $font-size-medium;
    font-weight: $font-weight-normal;
  }

  &.disabled {
    background: $color-grey60;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.gray {
  background: $color-grey20;

  &:hover {
    background-color: $color-grey10;
  }

  &.inverse {
    background: none;

    span {
      color: $color-grey20;
    }
  }
}

.lightGray {
  background: $color-grey90;

  span {
    color: $color-black;
  }

  &:hover {
    background-color: $color-white;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.red {
  background: $color-red;

  &:hover {
    background-color: $color-darkred;
  }

  &.inverse {
    background: none;

    span {
      color: $color-red;
    }
  }
}

.stretch {
  width: 100%;

  button {
    width: 100%;
  }
}


.with-end-icon {
  padding: 14px;
  gap: 8px;

  span {
    margin-right: auto;
    height: 18px;
  }

  svg {
    width: 30.5px;
    height: 18px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  .with-end-icon {

    span {
      line-height: 112%;

    }
  }
}

@supports (-moz-appearance:none) {

  .with-end-icon {

    span {
      line-height: 131%;

    }

  }
}
