@import "../../../theme.scss";

header {
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background-color: $color-black;
  color: $color-white;

  &.map-header {
    background-color: $color-white;
    color: $color-black;
    flex-direction: column;
    height: 42px;
    padding: 0 22px;

    .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 42px;

      &::after {
        content: "";
        height: 1px;
        bottom: 0;
        left: 50%;
        position: absolute;
        width: 125%;
        transform: translateX(-50%);
        border-bottom: 1px solid rgba($color-grey60, 0.15);
      }

      .top-header-start {
        display: flex;
        color: $color-grey20;
        align-items: center;

        .project-meta-data {
          font-size: $font-size-small;
          display: flex;
          align-items: center;

          .slash-separator {
            margin: 0 15px;
          }
        }

        .project-and-customer-name {
          margin: 0;
        }

      }

      .logo {
        display: flex;
        align-items: center;
        margin: 0 18px 0 0;
        cursor: pointer;
        color: $color-black;
        background: none;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        line-height: 0%;
        text-decoration: none;
        width: unset;

        p {
          margin: 0 0 0 18px;
        }
      }
    }


  }

  &.login-page{
    height: 60px;

    .top-header{
      height: 60px;
    }
  }

  .logo {
    display: block;
    background: url("../../../static/img/logo-workbench.svg") 30px center no-repeat;
    cursor: pointer;
    color: transparent;
  }

  .refresh-icon {
    cursor: pointer;
    margin-right: 12px;
  }

  .refresh-tooltip-message {
    margin: 0;
    padding: 0;
    font-size: $font-size-medium;
    line-height: 129%;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    white-space: nowrap;
    gap: 25px;

    .users-online {
      cursor: pointer;
    }

    &_linked-icon {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .make-a-wish {
        padding: 13px 0;

        input {
          height: 32px;
          width: 300px;
          margin-right: 30px;
          padding: 0 8px;
          font-size: $font-size-small;
          background-color: $color-grey10;
          border: 1px solid $color-grey20;
          border-radius: 2px;
          outline: none;

          &::placeholder {
            color: $color-grey60;
          }

          &:focus {
            border: 1px solid $color-grey90;
          }
        }
      }

      &-link {
        display: flex;
        align-items: center;
        height: 24px;
        font-size: $font-size-small;
        line-height: 200%;
        text-decoration: none;
        transition: .2s opacity ease-in-out;

        &--gettingStarted {
          background: url("../../../static/img/icon-guide.svg") left center no-repeat;
        }

        &--help {
          background: url("../../../static/img/icon-message.svg") left center no-repeat;
        }

        &:hover {
          opacity: 1;
        }

        svg {
          margin-right: 10px;
        }

        @media only screen and (max-width: 899px) {

          &--help, &--gettingStarted, &--about {
            display: none;
          }
        }
      }
    }

    &_options {
      user-select: none;

      .flyout-content {
        display: none;
        width: 115px;
        padding: 10px 0;
        margin: -10px 0 30px 0;
        background: $color-white;
        border-radius: 2px;
        box-shadow: 0 0 10px rgba($color-black, 0.25);
        font-size: $font-size-small;
        text-align: center;
        font-style: italic;
        color: $color-grey60;

        button {
          padding: 5px 15px;
          font-size: $font-size-small;
          width: 100%;
          cursor: pointer;
          border: none;
          outline: none;
          background: none;
          text-align: left;
          color: $color-black;

          &:hover {
            background-color: rgba($color-interactiveblue,0.2);
          }

          &:active {
            background-color: rgba($color-interactiveblue,0.4);
          }
        }
      }

      &.open {

        .flyout-content {
          display: block;
        }
      }
    }

    &_menu {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      position: relative;
      height: 25px;
      background: url("../../../static/img/icon-dropdown-arrow.svg") right 26px no-repeat;
      font-size: $font-size-small;
      line-height: 275%;
      padding: 0;
      opacity: .8;
      transition: .2s opacity ease-in-out;
      cursor: pointer;

      svg {
        margin-right: 5px;
      }

      &:hover {
        opacity: .9;
      }

      .options-flyout {
        display: none;
        position: absolute;
        bottom: -40px;
        right: 0;
        width: 140px;
        padding: 10px 0;
        margin: 0;
        background: $color-white;
        list-style: none;
        list-style-type: none;
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba($color-black, 0.25);

        li {
          list-style: none;
          list-style-type: none;
          height: 30px;
          padding: 0;
          width: 100%;
          margin: 0;

          a {
            display: block;
            width: 100%;
            height: 30px;
            padding: 0 15px;
            line-height: 214%;
            text-decoration: none;
            color: $color-black;

            &:hover {
              background-color: rgba($color-interactiveblue,0.2);
            }
          }
        }
      }

      &.open {
        opacity: 1;

        .user_menu {
          opacity: 1;
        }

        .options-flyout {
          display: block;
        }
      }
    }

    &.logged-out {
      display: none;
    }

    .default-menu{

      svg{
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }

      span {
        font-size: $font-size-medium;
        color: $color-black;
        font-weight: $font-weight-normal;
      }
    }
  }


  [data-count][data-badge-changelog]::after {
    display: block;
    background: $color-red;
    width: 5px;
    height: 5px;
    font-size: 0px;
    top: 2px;
  }
}
