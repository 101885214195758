@import "../../../theme.scss";

.InfraComponent-marker{

  .leaflet-marker-icon:not(.leaflet-marker-draggable){
    cursor: progress !important;
  }}

.smallIcon{
  transform: scale(0.5);
  transform-origin: top left;

  p {
    font-size: $font-size-medium;
  }

  .deployment-title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
  }
}

.verySmallIcon{
  transform: scale(0.3);
}

.infraComponent-container{
  width: fit-content;
  border-radius: 3px;
  box-shadow: 0px 4px 2px 0px rgba($color-black, 0.25);

  :nth-child(1){

    .deployment-title{    border-radius: 3px 3px 0 0;}
  }

}

.deployment-container{
  width: fit-content;
  border-radius: 3px;
  box-shadow: 0px 4px 2px 0px rgba($color-black, 0.25)
}

.individual-title{
  inline-size: max-content;
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.deployment-title{
  background: $color-red;
  color: $color-white;
  font-weight: $font-weight-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 6px 15px;
  text-wrap: nowrap;
  white-space: nowrap;
}

.smallTitle{
  min-inline-size: max-content;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;

  .amount-number{
    background-color: $color-darkred;
    padding: 2px 10px;
    border-radius: 50%;
  }
}


.infraComponent-information{
  padding: 5px 25px;
  background: $color-white;
  text-wrap: nowrap;
  border-bottom: 1px solid $color-grey80;

  p{
    margin: 0;
  }
}

.infraComponent-name{
  text-align: center;
  font-weight: $font-weight-bold;
  white-space: nowrap;
}

.infraComponent-device{
  text-align: center;
  font-size: $font-size-small;
  line-height: 167%;
  color: $color-grey30;
}
