ul.selector {

  li {
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }
}
