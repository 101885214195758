@import "../../../theme.scss";

.asp-data-changed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-black,0.5);
  z-index: 4;
  padding: 20px;
  overflow: scroll;

  .modal {
    background: $color-white;
    max-width: 600px;
    margin: 200px auto;
    padding: 35px;
    position: relative;

    .close {
      border: none;
      font-size: $font-size-heading-large;
      line-height: 40%;
      font-weight: $font-weight-light;
      position: absolute;
      right: 33px;
      cursor: pointer;
    }

    h2 {
      font-family: "Proxima Nova", arial, sans-serif;
      font-size: $font-size-heading-medium;
      line-height: 225%;
      margin-top: 0;
    }

    p {
      font-size: $font-size-medium;
      line-height: 129.3%;
    }

    footer {
      margin-top: 25px;
      text-align: right;

      button.okay {
        padding: 15px 50px;
        border: none;
        background: $color-red;
        border-radius: 2px;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-medium;
        line-height: 115%;
        color: $color-white;
        cursor: pointer;
      }

      .loading {
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: sub;
        margin-right: 10px;
        background: url("../../../static/img/icon-progress.svg") center center no-repeat;
        animation: spin .6s infinite linear;
        @keyframes spin {

          100% {
            transform:rotate(360deg);
          }
        }
      }
    }
  }
}

