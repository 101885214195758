@import "../../theme.scss";
$transition: 0.5s;

.sidebar {
  position: absolute;
  left: 0px;
  transition: $transition;
  height: calc(100% - 42px);
  top: 43px;

  .collapse-button {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    padding: 0;
    top: 110px;
    left: 352px;
    width: 14px;
    height: 26px;
    cursor: pointer;
    color: $color-white;
    background: $color-blue;
    box-shadow: 0px 1px 2px rgba($color-black, 0.25);
    border: none;
    border-radius: 2px;
    transition: $transition;
  }

  &.isSidebarCollapsed {
    left: -340px;

    .collapse-button {
      left: 8px;
      width: 24px;

      svg {
        transform: rotate(0);
      }
    }
  }
}
