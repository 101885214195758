@import "../../../../../theme.scss";

.locations-table {
  width: 500px;
  padding: 30px;
  padding-bottom: 155px;

  .status-container {
    padding: 60px 0;
    text-align: center;
    border-radius: 2px;
    color: $color-black;

    .status-message {
      font-size: $font-size-large;
      line-height: 125%;
      height: 20px;
      vertical-align: middle;

      .spinner {
        display: inline-block;
        vertical-align: sub;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background: url("../../../../../static/img/icon-progress.svg") center center no-repeat;
        animation: spin .6s infinite linear;
        @keyframes spin {

          100% {
            -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
  }



  .table-header {
    opacity: 0.5;
    color: $color-grey60;
    font-weight: $font-weight-normal;
    padding-bottom: 5px;
    user-select: none;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    // By default, React Virtualized makes containers overflow: hidden.
    // But since the button menu for rows need to overflow the container,
    // make sure that overflow is visible.
    overflow: visible !important;
    isolation: auto;
    z-index: 1;
    opacity: 1;
  }

  .ReactVirtualized__Table__Grid {
    overflow: visible !important;
    background:$color-white;
    border-radius: 2px;
    box-shadow: 0px 0px 5px rgba($color-black, 0.2);
    z-index: 0;
  }

  .ReactVirtualized__Table__rowColumn {
    display: inline-block;
    max-width: 90%;
  }

  .column{

    &:hover {
      background: rgba($color-interactiveblue, 0.063);
      cursor: pointer;

      .toggle {
        background: url("../../../../../static/img/icon-location-menu.svg") no-repeat center;
      }

    }

  }

  .menu {
    user-select: none;

    .toggle {
      background: none;
      width: 20px;
      height: 20px;
      border: none;
      cursor: pointer;
    }

    .flyout-content {
      position: absolute;
      right: 0;
      display: none;
      width: 115px;
      padding: 10px 0;
      margin: -2px 0 30px 0;
      background: $color-white;
      border-radius: 2px;
      box-shadow: 0 0 10px rgba($color-black, 0.25);
      font-size: $font-size-medium;
      text-align: center;
      font-style: italic;
      color: $color-grey60;
      z-index: 1;

      button {
        padding: 5px 15px;
        margin-bottom: 4px;
        margin-top: 4px;
        font-size: $font-size-medium;
        width: 100%;
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
        text-align: left;
        color: $color-black;

        &:hover {
          background-color: rgba($color-interactiveblue,0.2);
        }

        &:active {
          background-color: rgba($color-interactiveblue,0.4);
        }
      }
    }

    &.open {

      .flyout-content {
        display: block;
      }

      .toggle {
        background: url("../../../../../static/img/icon-location-menu-open.svg") no-repeat center;
      }
    }
  }
}
