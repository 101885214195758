@import "../../theme.scss";

.content-login-view {
  background-image: url("../../static/img/login-intro-bg-2.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;

  .login {
    background: linear-gradient(to right, rgba($color-black, 0.9), transparent);
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    gap: 1.75rem;
    // padding-top: max(100px,15vh); //JU
    padding-top: 15vh; //JU
    width: 900px;

    .introduction {

      p {
        color: $color-white;
        margin: 0;
      }

      .title {
        font-size: $font-size-heading-largest;
        font-weight: $font-weight-bold;
      }

      .subtitle {
        font-size: $font-size-heading-medium2;
        font-weight: $font-weight-normal;
        margin-top: -1rem;

        span{
          font-size: $font-size-medium;
          position: absolute;
          font-weight: $font-weight-light;
        }
      }
    }

    form {
      font-size: $font-size-medium;
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      padding: 1.5rem;
      gap: 1.25rem;
      min-height: 250px;
      justify-content: center;
      max-width: 425px;

      p,h2 {
        margin: 0;
      }

      p:first-child {
        margin: 0;
        font-size: $font-size-heading-medium2;
        font-weight: $font-weight-extra-bold;
        line-height: 130%;
      }

      .inputs {

        p {
          margin: 26px 0px 11px;
          font-size: $font-size-medium;
          font-style: normal;
          font-weight: $font-weight-bold;
          line-height: 130%;
        }

        .input-container {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          .textInput {
            height: 46px;
            margin-right: 5px;
          }
        }
      }

      a {
        color: $color-blue;
        text-decoration: none;
        cursor: pointer;
      }
    }



    .information {
      margin-top: -1rem;
      margin-bottom: 0.25rem;
      color: $color-white;
      font-size: $font-size-heading-small;
      font-weight: $font-weight-light;

      p {
        display: flex;
      }

      svg {
        width: 4rem;
        margin-top: 5px;
      }
    }

    .footer {
      margin-bottom: 0.938rem;
      margin-top: auto;

      p {
        color: $color-white;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin: 0;

        a {
          text-decoration: none;
          margin-right: 25px;
        }
      }
    }
  }

  .VersionStamp {
    color: $color-white !important
  }

  @media (max-width: 450px) {

    .login {
      width: 95vw;

      .introduction {

        .title {
          font-size: $font-size-heading-extra-large;
        }

        .subtitle {
          font-size: $font-size-heading-medium;
        }
      }

      .information{
        font-size: $font-size-medium;
      }
    }
  }
}
