@import "../../../theme.scss";

.info-panel {
  position: absolute;
  top: 0;
  left: 360px;
  width: 500px;
  transform: translateX(-500px);
  transition: all 500ms cubic-bezier(1, 0, 0, 1);
  overflow: hidden;
  background: $color-grey90;
  box-shadow: 2px 0px 5px rgba($color-black, 0.15);
  z-index: 2;
  height: 100%;
  //form { position: absolute; top: 0; right: 0; left: 0; bottom: 0;}

  .close-panel {
    position: absolute;
    z-index: 1;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 27px;
    padding: 3px;
    outline: none;
    border: none;
    cursor: pointer;
    background: $color-grey90;
    border-radius: 10px;
    box-shadow: $color-grey90 0 0 10px 10px;

    svg {
      stroke: $color-grey60;
    }

    &:hover {

      svg {
        stroke: $color-grey40;
      }
    }

    &:active {

      svg {
        stroke: $color-grey20;
      }
    }
  }

  .info-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;

    .header-title {
      height: 70px;
      border-bottom: 1px solid $color-grey70;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;

      .header-instruction {
        display: flex;
        font-size: $font-size-heading-medium;
        width: 100%;
        height: 70px;
        line-height: 350%;
        margin: 0;
      }

      .header-label {
        display: flex;
        width: 100%;

        input {
          font-size: $font-size-heading-medium;
          font-weight: $font-weight-bold;
          width: 100%;
          height: 70px;
          line-height: 350%;
          margin: 0;
          outline: none;
          border: none;
          background: none;
        }
      }

      .header-metro {
        width: 100%;
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: $font-size-heading-medium;
          height: 70px;
          line-height: 350%;
          margin: 0;
        }

        button {
          display: block;
          height: 30px;
          padding: 0 16px;
          margin: 20px 30px 20px;
          font-size: $font-size-medium;
          background: $color-grey80;
          color: $color-black;
          outline: none;
          border: none;
          border-radius: 2px;
          cursor: pointer;

          &:hover {
            background-color: $color-grey70;
          }

          &:active {
            background-color: $color-grey60;
          }
        }
      }
    }

    .header-tabs {
      padding: 0 30px;
      height: 50px;
      border-bottom: 1px solid $color-grey70;
      display: flex;
      justify-content: flex-start;

      a {
        margin-right: 30px;
        line-height: 172%;
        font-size: $font-size-medium;
        padding: 14px 0 10px;
        border-bottom: 3px solid transparent;
        color: $color-grey60;
        text-decoration: none;

        &:hover {
          color: $color-grey40;
        }

        &:active {
          color: $color-red;
        }

        &.selected {
          border-bottom-color: $color-red;
          color: $color-red;
        }
      }
    }
  }

  .info-content {
    position: absolute;
    top: 120px;
    right: 0;
    bottom: 70px;
    left: 0;
    overflow: scroll;

    .content-view {
      padding: 0;
      /* INFO PANEL FORM ELEMENTS */

      .content-section {
        border-top: 1px solid $color-grey70;
        padding: 25px 30px 30px;

        &:first-child {
          border-top: none;
        }

        p {
          font-size: $font-size-medium;
          line-height: 143%;

          &.instructions {
            margin-top: 10px;
            margin-bottom: 30px;
          }
        }

        label,
        h4 {
          display: block;
          font-size: $font-size-medium;
          font-weight: $font-weight-bold;
          margin: 0 0 10px;
        }

        input,
        textarea {
          width: 100%;
          padding: 0 10px;
          font-size: $font-size-medium;
          border: 1px solid $color-grey60;
          border-radius: 2px;
          outline: none;
          background-color: $color-grey90;

          &:focus {
            border-color: $color-grey20;
          }
        }

        input {
          height: 40px;
          line-height: 40px;
        }

        textarea {
          height: 120px;
          padding: 10px 10px;
          line-height: 20px;
        }

        table {
          width: 100%;
          font-size: $font-size-medium;

          tr {

            th,
            td {
              border-bottom: 1px solid $color-grey80;
            }

            th {
              padding: 5px 0;
              font-weight: $font-weight-normal;
              color: $color-grey60;

              &.availability-column {
                width: 100px;
              }

              &.delete-column {
                width: 30px;
              }

              &.activate-column {
                width: 70px;
                text-align: right;
              }
            }

            td {
              padding: 10px 0;

              .delete-row,
              .toggle-visibility-row {
                width: 24px;
                height: 24px;
                border: none;
                outline: none;
                cursor: pointer;
                opacity: 0.7;
                //background: url(../../img/icon-trash.svg) center center no-repeat;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }

        .activate-switch {
          position: relative;
          float: right;
          width: 35px;
          height: 20px;
          background-color: $color-grey60;
          border: none;
          outline: none;
          border-radius: 9999px;
          cursor: pointer;

          &:hover {
            background-color: $color-grey40;
          }

          .switch-knob {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
            background-color: $color-white;
            border-radius: 9999px;
          }

          &.on {
            background-color: $color-red;

            .switch-knob {
              left: 18px;
            }
          }
        }

        .use-case {
          margin-bottom: 10px;
          background: $color-grey80;
          color: $color-grey60;
          border-radius: 2px;

          p {
            font-size: $font-size-small;
            color: $color-grey40;
          }

          h3,
          h4,
          h5,
          h6 {
            margin: 0;
            padding: 0;
            font-weight: $font-weight-normal;
          }

          h3 {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            line-height: 313%;
            padding-left: 40px;
          }

          h4 {
            font-size: $font-size-medium;
            line-height: 172%;
            padding-left: 20px;
            color: $color-black;
          }

          h5 {
            font-size: $font-size-very-small;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          h6 {
            font-size: $font-size-medium;
            line-height: 185%;
            padding-left: 20px;
            color: $color-black;
          }

          button.expand-content {
            outline: none;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            background-position: 4px center;
            background-repeat: no-repeat;
          }

          ul,
          li {
            margin: 0;
            padding: 0;
            list-style: none;
            list-style-type: none;
          }

          .use-case-header {
            display: flex;
            justify-content: flex-start;

            .expand-content {
              background-position: 18px center;
            }

            .use-case-controls {
              padding: 15px;
              font-size: $font-size-small;
              line-height: 167%;
              color: $color-grey60;
            }
          }

          .use-case-content {
            padding: 10px 0 20px;
          }

          .expandable-header {
            display: flex;
            justify-content: flex-start;

            .ioakb-link {
              margin-left: 5px;
              width: 20px;
              padding: 5px;
              //background: url(../../img/icon-external-link.svg) center center no-repeat;
            }
          }

          .expandable-content {
            padding-left: 20px;

            &.use-case-content {
              padding-left: 40px;
              border-top: 1px solid $color-grey70;
            }
          }

          &.active {
            color: $color-grey40;
            background: $color-white;
            box-shadow: 0px 0px 10px rgba($color-black, 0.25);
          }
        }

        .expandable {

          &.open {
            //background-color: rgba(255,0,0,.1); // DEBUGGIN'

            .expandable-content {
              display: block;
            }

            .expandable-header {
              //background-color: rgba(255,0,0,.1); // DEBUGGIN'
              //.expand-content { background-image: url(../../img/icon-minus.svg); }
            }
          }

          &.closed {

            .expandable-content {
              display: none;
            }

            .expandable-header {
              //background-color: rgba(255,0,0,.1); // DEBUGGIN'
              //.expand-content { background-image: url(../../img/icon-plus.svg); }
            }
          }

          .use-case-header {
            justify-content: space-between;
          }
        }

        .location-type-switch {
          display: flex;
          justify-content: flex-start;

          button {
            font-size: $font-size-medium;
            height: 40px;
            padding: 0 15px 0 32px;
            margin: 0 10px 0 0;
            border: 1px solid $color-grey60;
            border-radius: 2px;
            outline: none;
            background-color: $color-grey90;
            background-position: 10px center;
            background-repeat: no-repeat;
            color: $color-black;
            cursor: pointer;

            &:hover {
              color: $color-red;
              border: 1px solid $color-red;
            }

            &:active {
              color: $color-red;
            }

            &.selected {
              color: $color-white;
              background-color: $color-red;
              border-color: $color-red;
            }

            &.office-type {
              //background-image: url(../../img/icon-office-switch-default.svg);

              &.selected {
                //background-image: url(../../img/icon-office-switch-selected.svg);
              }
            }

            &.datacenter-type {
              //background-image: url(../../img/icon-datacenter-switch-default.svg);

              &.selected {
                //background-image: url(../../img/icon-datacenter-switch-selected.svg);
              }
            }
          }
        }

        .add-services {

          .no-services {
            margin-top: 20px;
          }

          table {
            margin-top: 25px;
          }
        }

        .related-notes {
          padding-top: 20px;

          h4 {
            margin-bottom: 20px;
          }

          .no-notes {
            display: none;
            padding: 20px 0;
            margin-bottom: 10px;
            border: 1px solid $color-grey70;
            border-radius: 2px;
            text-align: center;
            color: $color-grey60;
            font-size: $font-size-medium;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            list-style-type: none;

            li {
              display: block;
              margin-bottom: 10px;
              padding: 20px 20px 15px 40px;
              background-color: $color-white;
              background-position: 14px 16px;
              background-repeat: no-repeat;
              box-shadow: 0px 2px 4px rgba($color-black, 0.25);

              h5 {
                margin: 0;
                margin-bottom: 10px;
                line-height: 100%;
              }

              p {
                margin: 0;
                line-height: 143%;
              }
              /*
              &.location-note {
                &.office { background-image: url(../../img/icon-office-default.svg); }
                &.datacenter { background-image: url(../../img/icon-datacenter-default.svg); }
              }
              &.service-note {
                &.local { background-image: url(../../img/icon-service-local-default.svg); }
                &.remote { background-image: url(../../img/icon-service-remote-default.svg); }
              }
              */
            }
          }
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .info-footer {
    position: absolute;
    height: 70px;
    border-top: 1px solid transparent;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px 30px;

    button {
      height: 40px;
      border: none;
      outline: none;
      border-radius: 2px;
      line-height: 286%;
      font-size: $font-size-medium;
      cursor: pointer;

      &:hover {
        background-color: $color-darkred;
      }

      &:active {
        background-color: $color-grey20;
      }
    }

    &.info-edit-mode {
      display: flex;
      justify-content: center;

      .delete-item {
        background-color: $color-red;
        display: block;
        width: 100%;
        color: $color-white;

        &:hover {
          background-color: $color-darkred;
        }
      }
    }

    &.info-create-mode {
      display: flex;
      justify-content: flex-end;

      button {
        padding: 0 50px;
      }

      .create-item {
        background-color: $color-red;
        color: $color-white;

        &:hover {
          background-color: $color-darkred;
        }

        &:active {
          background-color: $color-grey20;
        }

        &:disabled {
          background-color: $color-grey60;
          cursor: not-allowed;
        }
      }

      .discard-item {
        background: none;
        color: $color-red;

        &:hover {
          color: $color-darkred;
          background: none;
        }

        &:active {
          color: $color-grey20;
          background: none;
        }
      }
    }
  }
  /* State machine */

  &.metro {

    &.create {

      .info-header .header-title {

        .header-label {
          display: none;
        }

        .header-metro {
          display: none;
        }

        .header-instruction {
          display: flex;
        }
      }

      .header-tabs {
        display: none;
      }

      .info-content {
        top: 70px;
      }
    }

    &.edit {

      .info-header .header-title {

        .header-label {
          display: none;
        }

        .header-metro {
          display: flex;
        }

        .header-instruction {
          display: none;
        }
      }
    }
  }

  &.service {

    .info-header .header-title {

      .header-label {
        display: none;
      }

      .header-metro {
        display: none;
      }

      .header-instruction {
        display: flex;
      }
    }

    &.create {

      .header-tabs {
        display: none;
      }

      .info-content {
        top: 70px;
      }
    }
  }

  &.location {

    .info-header .header-title {

      .header-label {
        display: flex;
      }

      .header-metro {
        display: none;
      }

      .header-instruction {
        display: none;
      }
    }

    .header-tabs {
      display: none;
    }

    .info-content {
      top: 70px;
    }
  }

  &.create {

    .info-footer.info-create-mode {
      display: flex;
    }

    .info-footer.info-edit-mode {
      display: none;
    }
  }

  &.edit {

    .info-footer.info-create-mode {
      display: none;
    }

    .info-footer.info-edit-mode {
      display: flex;
    }
  }
}
