@import "../../../theme.scss";

.gray-information{
  background-color: $color-grey80;
  padding: 0px 10px;
  border-radius: 20px;
  font-size: $font-size-small;
  color: $color-grey40;
  width: fit-content;
}

.project-meta{
  display: flex;
  align-items: center;
}
