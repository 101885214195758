@import "../../../../../theme.scss";

.AddMultipleLocationsView {
  display: grid;
  grid-template-rows: 70px min-content min-content auto 70px !important;
  height: 100%;

  .file-zone {
    padding: 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);
    display: flex;
    flex-direction: column;
  }

  .result-message {
    background: $color-white;
    border: 0.25px solid $color-grey20;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba($color-black, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 18px 20px;
    margin: 10px 0;
    justify-content: space-between;

    .result-message-info-wrapper {
      display: flex;
      align-items: flex-start;
    }

    p {
      color: $color-grey20;
      margin: 0;
      font-size: $font-size-large;
      line-height: 131%;
      white-space: pre-wrap;
    }

    span {
      cursor: pointer;
      color: $color-interactiveblue;
      font-weight: $font-weight-medium;
      font-size: $font-size-medium;
      line-height: 173%;
      letter-spacing: 0.46px;
    }

    svg {
      margin-right: 20px;
      min-width: 22px;
    }
  }

  .dropzone-wrapper {
    margin: 30px 0;
    padding: 40px;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='16%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    .dropzone-title {
      font-weight: $font-weight-bold;
      font-size: $font-size-heading-medium;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.5px;
      color: $color-grey30;

      span {
        color: $color-interactiveblue;
      }
    }

    .loader {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: sub;
      background: url("../../../../../static/img/loader-xl.svg") center center no-repeat;
      animation: spin 1s infinite linear;
      @keyframes spin {

        100% {
          transform: rotate(1turn);
        }
      }
    }

    .loading-file-title {
      color: rgba($color-black, 0.87);
      font-weight: $font-weight-bold;
      font-size: $font-size-heading-medium;
      line-height: 150%;
      letter-spacing: 0.5px;
      margin: 30px 0 0 0;
      text-align: center;
      word-wrap: break-word;
      max-width: 350px;
    }

    .dropzone-info {
      font-size: $font-size-medium;
      line-height: 129%;
      text-align: center;
      margin: 0;
      color: $color-grey30;
    }

    &.dragging {
      opacity: 0.7;
    }
  }

  .download-template-link {
    text-decoration: none;

    .download-template {
      background: $color-grey20;
      border: 0.25px solid $color-grey20;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px 1px $color-grey80;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: 11px 12px;

      p {
        color: $color-white;
        margin: 0;
        font-size: $font-size-medium;
      }
    }
  }

  > input {
    grid-row: 1;
    padding: 30px;
    padding-right: 70px;
    font-size: $font-size-heading-medium;
    font-weight: $font-weight-bold;
    font-family: "Roboto", helvetica, sans-serif;
    color: $color-black;
    border: none;
    border-bottom: 1px solid rgba($color-black, 0.2);
    background: transparent;
  }

  > input::placeholder {
    opacity: 0.2;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    margin-bottom: 30px;
    font-size: $font-size-medium;
    color: $color-grey20;
  }

  .type {
    grid-row: 2;
    padding: 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);
  }

  .error {
    color: $color-red;

    h4 {
      margin-bottom: 0;
    }
  }

  .note {
    grid-row: 4;
    padding: 30px;

    textarea {
      resize: none;
      margin-top: 15px;
      padding: 15px;
      background: transparent;
      border: 1px solid $color-grey60;
      border-radius: 2px;
      color: $color-black;
      width: 100%;
      height: 90px;
      font-family: inherit;
      font-size: $font-size-medium;
      line-height: 150%;
    }

    textarea::placeholder {
      color: $color-grey60;
    }
  }

  .buttons {
    grid-row: 5 !important;
  }

  .loading-container {
    display: flex;
    align-items: center;
  }

  .loading-text {
    font-family: "Proxima Nova", arial, sans-serif;
    font-size: $font-size-medium;
    line-height: 129%;
    color: $color-grey60;
  }

  .templateDownload {
    font-family: "Proxima Nova", arial, sans-serif;
    font-size: $font-size-medium;
    line-height: 129%;
    margin-left: 10px;
    font-weight: $font-weight-thin;
    color: $color-interactiveblue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .upload-label {
    margin-bottom: 30px;
  }

  .uploaded-file {
    font-size: $font-size-medium;
  }

  .excel {
    width: 32px;
    height: 32px;
    margin-right: 14px;
    background: url("../../../../../static/img/icon-excel.svg") center center no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
  }
}
