@import "../../../theme.scss";

.checkbox-container {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding-left: 15px;

  .checkbox {
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    display: inline-flex;
    border: 1px solid $color-grey40;

    &.checked {
      background: url("../../../static/img/icon-checked.svg") center center no-repeat;
      background-color: $color-interactiveblue;
      border: 1px solid $color-interactiveblue;
    }

    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &:hover .checkbox {
    border: 1px solid $color-interactiveblue;
  }
}
