@import "../../../theme.scss";

.AddressAutocompleteTextBox {
  position: relative;
  width: 100%;
  background-color: $color-white;

  input {
    width: 92%;
    border: none;
    font-size: $font-size-medium;
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
    height: 50px;
  }

  .no-result{
    overflow: hidden !important;

    p{
      width: 100%;
      text-align: center;
      color: $color-grey60;
      font-size: $font-size-large;
      padding: 8px;
    }
  }

  .content-options {
    position: absolute;
    background: $color-white;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    top: 42px;
    z-index: 100;
    margin-top: 12px;
    border-radius: 0;
    box-shadow: 0 0 0 1px rgba($color-black, 0.10), 0 4px 11px rgba($color-black, 0.10);

    p{
      margin: 0px;
    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;
        margin-bottom: 8px;
        cursor: pointer;
        padding: 4px 16px;

        &:hover {
          background-color: $color-waterblue;
        }
      }
    }
  }
}
