@import "../../../theme.scss";

.getting-started {
  max-width: 900px;

  main {
    display: flex;
    flex-direction: column;
  }

  .notion-asset-wrapper {
    margin: 0;

    video{
      width: 331px;
      height: 189px;
      box-shadow: 2px 2px 3px rgba($color-black, 0.2);
      border: 1px solid $color-black;
    }
  }
}

.notion-h1 {
  color: rgba($color-red, 0.7);
}

.loading-projects.resources {
  margin-top: 25px;
}

@media (max-width: 1100px) {

  .getting-started {
    max-width: 714px;
  }
}

@media (max-width: 880px) {

  .getting-started {
    max-width: 550px;

    .notion-asset-wrapper {
      margin: 0;

      video{
        width: 244px;
        height: 139.5px;
      }
    }
  }
}
