@import "../../../theme.scss";

.map {

  .servicesMarker {
    display: flex;
    flex-flow: column wrap;
    width: min-content !important;
    height: min-content !important;
    max-height: 310px;

    &.smallIcon {
      max-height: 160px;
    }
  }

  .servicesMarker.dragging {

    .service {
      border-color: $color-interactiveblue;
      box-shadow: 0px 1px 5px $color-interactiveblue;
    }
  }

  .service {
    box-shadow: 0px 1.28831px 3.86493px rgba($color-black, 0.25);
    border-radius: 3.86493px;
    border: 1.5pt solid $color-grey70;
    background: $color-white;
    padding: 5px 10px;
    margin-top: 5px;
    margin-left: 5px;

    &.platform-card-wrapper {
      background: $color-red;
      border: none;
      border-radius: 8px;

      .platform-card {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-red;
        color: $color-white;
        font-size: $font-size-medium;
        line-height: 122%;
        text-align: center;
        font-weight: $font-weight-semi-bold;
      }

      &.small {
        border: none;
        border-radius: 3px;

        .platform-card {
          padding: 0 2px;
          font-size: $font-size-smallest;
          line-height: 115%;
        }
      }
    }

    &.selected {
      border-color: $color-serviceorange;
      box-shadow: 0px 1px 5px $color-serviceorange;
    }

    &:hover {
      border-color: $color-black;
    }

    &.loading {
      opacity: 0.8;
    }
  }

  .service.small {
    border-radius: 1px;
    padding: 0.5px 1px;
    margin-top: 2.5px;
    border-width: 1pt;
  }

  .serviceMarker {
    background: url("../../../static/img/services.png");
    height: 100%;

    &.no-logo {
      text-align: center;
      background: $color-white;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

  }

  .remote {
    border-style: dashed;
  }
}
