@import "theme.scss";  

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  color: inherit;
  outline: none;
  font-family: "Roboto", helvetica, arial, sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: $color-grey90;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: $color-black;
}

button {
  user-select: none;
}

#root {
  text-align: left;
  position: relative;

  .content-page {
    margin-top: 42px;
    z-index: 0;
  }
}

.rotate180-icon{
  transform: rotate(180deg);
}