@import "../../../theme.scss";

.VersionStamp {
  user-select: none;
  margin: 15px 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: $font-size-small;
  color: $color-grey60;
}
