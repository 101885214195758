@import "../../../theme.scss";

.undo-toast {
  font-size: $font-size-medium;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button.undo-btn {
    width: 50px;
    height: 26px;
    font-size: $font-size-medium;
    line-height: 130%;
    color: $color-yellow;
    background: transparent;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {

      &:not(.loading) {
        background: $color-grey50;
      }
    }
  }

  .loader {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: sub;
    background: url("../../../static/img/icon-progress.svg") center center no-repeat;
    animation: spin .6s infinite linear;
    @keyframes spin {

      100% {
        transform:rotate(360deg);
      }
    }
  }
}
