@import "../../../theme.scss";

.embedded {

  &.company{top: 3px !important;}
}

input[type=radio] {
  accent-color: $color-red;
}

.modal-warning-text{
  color:$color-red;
}
