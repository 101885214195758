@import "../../../../theme.scss";

.table {
  width: 100%;
  background: $color-white;
  border-radius: 0px;
  // box-shadow: 0px 0px 5px rgb(0 0 0 / 20%); //JU
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .column {
    border-bottom: solid 0.75px rgba($color-grey60, 0.388);
    padding: 14px;
    font-size: $font-size-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 51.4px;

    .item {
      display: flex;
      flex: 1 1 0;
      width: 0;
      justify-content: center;
    }

    .first {
      min-width: 30%;
      justify-content: flex-start;
    }

    .last {
      justify-content: flex-end;
    }

    .infraComponent-item {
      gap: 12px;
      align-items: center;

      &.last{
        flex: 0;
        min-width: fit-content;
      }

      .icon {
        align-items: center;
        display: flex;

        &:hover {
          opacity: 1;
        }
      }

      .icon-container{
        height: 15px;
        display: flex;
        gap: 3px;

        svg{
          opacity: 0.4;
        }

        svg:hover{
          opacity: 1;
        }

        .tooltip {
          position: relative;
          display: inline-block;
        }

        .tooltip .tooltiptext {
          visibility: hidden;
          background-color: $color-black;
          color: $color-white;
          text-align: center;
          border-radius: 6px;
          padding: 5px 10px;
          position: absolute;
          z-index: 1;
          bottom: 125%;
          left: 50%;
          margin-left: -60px;
          opacity: 0;
          transition: opacity 0.3s;
          font-size: $font-size-small;
          white-space: nowrap;
        }

        .tooltip:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }

        .otherIcons{
          display: flex;
          gap: 3px;
          opacity: 0;
          transition: opacity .3s ease-in-out;

        }

        .show{
          opacity: 1;
        }
      }

    }

    &.add-item {
      background: linear-gradient(90deg, $color-grey90 0.09%, $color-grey80 99.93%);
    }

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .toggle-button {
      margin: 0;
    }

    .latency {

      b {
        margin: 0 5px;
        font-size: $font-size-heading-large;
        line-height: 0;
        vertical-align: middle;
      }
    }

    .container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .input {
      min-width: 50%;
      justify-content: center;
    }

    .color-picker {
      position: relative;
      flex-shrink: 0;

      .color {
        width: 18px;
        height: 18px;
        margin: 3px;
        opacity: 1;
        border-radius: 20px;
        cursor: pointer;
      }

      .current,
      .selected {
        border: 2px solid $color-white;
        box-shadow: 0px 0px 4px rgba($color-black, 0.7);
      }

      .dropdown {

        &:before {
          content: "";
          border: 10px solid transparent;
          border-bottom-color: $color-grey90;
          position: absolute;
          top: -20px;
          right: 38px;
        }
        position: absolute;
        z-index: 1;
        right: -37px;
        top: 33px;
        width: 203px;
        display: flex;
        background-color: $color-grey90;
        border: 1px solid $color-grey70;
        border-radius: 2px;
        padding: 5px;
        padding-right: 10px;
        box-shadow: 0px 0px 20px rgba($color-black, 0.3);
      }
    }

    .color-picker-backdrop {
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .close-triangle-icon {

      svg {
        transform: rotate(270deg);
      }
    }

    .clickable {
      cursor: pointer;
    }

    .icon {
      opacity: 0.7;
      height: 20px;

      &:hover {
        opacity: 1;
      }

      &.invisible {
        opacity: 0.1;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .infraComponent-trash {
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-div {
      margin-right: 8px;
    }

    .add-input {
      width: 307px;
      flex-shrink: 0;
      border: none;
      background: none;
    }

    .add-input::placeholder {
      color: $color-grey60;
      font-size: $font-size-medium;
      font-style: normal;
      font-weight: $font-weight-normal;
    }

    .add-button {
      display: flex;
      width: 45px;
      height: 24px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      text-align: center;
      border-radius: 47px;
      opacity: 0.9;
      background: $color-white;
      border: none;
      cursor: pointer;

      &.disabled {
        color: $color-grey60; //disabled
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .subColumn {
    background: $color-grey90;
  }

  .light-text {
    color: $color-grey60 !important;
    width: 65px;
  }

  .disabled {
    color: $color-grey60;
    font-weight: $font-weight-light;
  }

  .open {

    .expandable-content {
      display: block;
      border-bottom: solid 0.75px rgba($color-grey60, 0.388);
      padding: 0 14px 14px;
      position: relative;

      .content-options {
        position: absolute;
        background: $color-white;
        max-height: 127px;
        overflow-y: auto;
        width: 251px;
        z-index: 100;
        margin-top: 6px;
        border-radius: 4px;
        border: 1px solid $color-grey70;
        box-shadow: 0px 1px 4px 0px rgba($color-black, 0.25);
        padding-bottom: 6px;

        p{
          margin: 0px;
        }

        ul {
          padding: 0px;
          margin: 0px;

          p {
            color: $color-black;
            font-size: $font-size-very-small;
            line-height: 200%;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            opacity: 0.5;
            padding: 6px 16px;

          }

          li {
            list-style: none;
            cursor: pointer;
            padding: 6px 16px;
            color: $color-black;
            font-size: $font-size-medium;
            font-weight: $font-weight-normal;
            line-height: 143%;

            &:hover {
              background-color: $color-waterblue;
            }
          }
        }
      }
    }

    .column {
      border-bottom: none;
    }
  }

  .closed {

    .expandable-content {
      display: none;
    }

    .open-icon {

      svg {
        transform: rotate(270deg);
      }
    }
  }

  button.expand-content {
    outline: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    background-position: 4px center;
    background-repeat: no-repeat;

    &.infraComponent-information{
      display: flex;
      gap: 3px;
      align-items: center;
      max-width: 80%;
    }

    &:disabled {
      cursor: default;
    }



    &.close-icon {
      height: 13px;
    }
  }

  .infraComponent-icon{
    width: inherit;
  }

  .open-icon {
    display: flex;
    cursor: pointer;

    svg {
      width: 25px;
      visibility: visible;
      overflow: visible;
      transform: rotate(0deg);
    }
  }

  .deployment-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    border-radius: 0px;

    p {
      font-size: $font-size-very-small;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 130%;
      width: 62px;
      flex-shrink: 0;
      opacity: 0.5;
      margin: 0;
    }

    .deployment-buttons {
      gap: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .input-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .resize-container {
    display: inline-block;
    position: relative;
    height: 36px;
  }


  .resize-input,
  .resize-text {
    margin: 0;
    padding-left: 9px;
    border: none;
    border-radius: 4px;
    height: 36px;
    font-size: $font-size-medium;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 143%;
    max-width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .resize-text {
    display: inline-block;
    visibility: hidden;
    white-space: pre;
    padding-right: 5.5px;
  }

  .resize-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::placeholder {
      color: $color-black;
      opacity: 0.4;
      font-size: $font-size-medium;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 143%;
    }
  }
}
