@import "../theme.scss";

.maintenance{
  height: 100vh;
  background-image: url("./maintenance.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .banner {
    width: 652px;
    height: 324px;
    background-color: $color-white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 44px;

    .title {
      font-size: $font-size-heading-extra-large;
      font-weight: $font-weight-ultra-bold;
    }

    .text {
      margin: 0;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      line-height: 143%;
      width: 55%;
      text-align: center;
      font-style: normal;
    }
  }
}
