@import "../../../../../theme.scss";

.AddLocationsView {
  display: grid;
  grid-template-rows: 70px min-content min-content auto 70px !important;
  height: 100%;

  p {
    margin: 0;
    font-size: $font-size-medium;
  }

  .upload-file {
    grid-row: 2;
    display: flex;
    padding: 15px 30px;
    border-bottom: 1px solid rgba($color-black, 0.2);
    gap: 4px;

    p {
      color: $color-grey20;
    }

    .link {
      display: flex;
      gap: 4px;
      cursor: pointer;

      p {
        text-decoration: underline;
      }

      svg {
        width: 15px;
        visibility: visible;
        overflow: visible;
        margin-top: 4px;

      }
    }

  }

  .location {
    padding: 20px 30px;
    border-bottom: 1px solid rgba($color-black, .2);
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .card-list {
    overflow-y: auto;
  }

  .buttons {
    grid-row: 5 !important;
  }

  .AddressAutocompleteTextBox {

    input {
      height: 40px !important;
    }

    .content-options {
      width: calc(100% + 57px);
      left: -57px;
      margin-top: 4px;
    }
  }

  .location-card {
    position: relative;
    height: fit-content;
    background-color: $color-white;
    margin: 20px 30px;
    padding: 10px 15px 15px 15px;

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .connect {
        display: flex;
        align-items: center;
        gap: 5px;

        .select__control {
          width: 100px;
          height: 15px;
          border: none;
          box-shadow: none;
          cursor: pointer;

          .select__indicator-separator {
            display: none;
          }
        }
      }

      .visibility {
        color: $color-grey50;
        font-size: $font-size-medium;
        cursor: pointer;

        &:hover {
          color: $color-blue !important;

        }
      }
    }

    .information {
      color: $color-grey20;
      font-size: $font-size-medium;
      display: flex;
      margin-top: 10px;
      align-items: center;

      p {
        margin: 0;
        width: 30%;
      }

      input {
        width: 70%;
      }

      .wrapper {
        position: relative;
        margin-left: 9%;
        width: 100%;


        .size-buttons {
          left: 69%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          gap: 5px;

          .option {
            height: 24px;
            width: 24px;
            border: 1px solid $color-grey80;
            cursor: pointer;
            text-align: center;
            font-size: $font-size-medium;
            color: $color-grey50;

            &:hover {
              background: $color-red;
              color: $color-white;
            }
          }

          .selected {
            background: $color-red;
            color: $color-white;
          }
        }

        .no-spin::-webkit-inner-spin-button,
        .no-spin::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
        }

        .no-spin {
          -moz-appearance: textfield !important;
        }

        input {
          width: 100%;
        }
      }
    }

    .delete {
      height: 24px;
      width: 24px;
      cursor: pointer;
      left: 91%;
      position: absolute;
      margin-top: 8px;
      z-index: 1;
    }

    .AddressAutocompleteTextBox {

      .content-options {
        width: calc(100% + 54px) !important;
        left: -54px !important
      }
    }
  }

  .isLoading,
  .isLoading * {
    cursor: progress !important;
  }
}
