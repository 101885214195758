@import "theme.scss";

.Toastify {

  &__progress-bar {
    background: $color-yellow;
    bottom: unset;
    top: 0;
  }

  &__toast {
    padding: 8px 18px;
    min-height: unset !important;
    border-radius: 0;

    &-body {
      padding: 0;
    }

    &-theme--dark {
      background: $color-grey20;
    }
  }

  &__close-button {
    align-self: center;
    opacity: 1;
    margin-left: 15px;
  }
}