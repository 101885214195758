@import "../../../theme.scss";
$no-collapsed-width: 360px;

.phase-panel {

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: $color-grey10;
    position: absolute;
    z-index: 3;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }

  &.isSidebarCollapsed {

    &:after {
      pointer-events: all;
      opacity: 1;
    }
  }
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: $no-collapsed-width $no-collapsed-width;
  grid-template-rows: min-content min-content auto min-content;
  left: 0;
  width: $no-collapsed-width;
  background: $color-grey20;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-row: 3;

    .loading {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: sub;
      background: url("../../../static/img/loader-xl.svg") center center no-repeat;
      animation: spin 0.6s infinite linear;
      @keyframes spin {

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .loading-message {
      font-size: $font-size-large;
      line-height: 131%;
      color: $color-white;
    }
  }

  .template-container{
    grid-row: 1;
    height: fit-content;
    padding: 20px 18px 0 18px;
    background: $color-grey10;
    color: $color-white;

    .clickable{
      cursor: pointer;
    }

    .header{
      height: 36px;
      display: flex;
      justify-content: space-between;
      margin: 0px 0px 13px 0px;
      align-items: center;

      .button{
        padding: 10px 15px;
      }

      .title{

        .name{
          display: flex;
          align-items: center;
          gap: 8px;

          h2{
            white-space: nowrap;
            min-width: 0;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: $font-size-large;
            margin: 0;
            font-weight: $font-weight-bold;

          }

          svg{
            cursor: pointer;
            vertical-align: text-bottom;
          }
        };

        .email{
          font-size: $font-size-small;
          margin: 0;
          font-weight: $font-weight-normal;
        }
      }

      .visibility-section{
        display: flex;
        align-items: center;
        gap: 7px;
      }

      .saved{
        border: 1px solid $color-white;
        height: fit-content;
        border-radius: 3px;
        display: flex;
        align-items: center;
        font-size: $font-size-medium;
        cursor: pointer;
        padding: 5px;
        font-weight: $font-weight-normal;
        line-height: 130%;
        gap: 8px;
        box-shadow: 0px 1px 3px rgba($color-black, 0.1);

        svg{

          path{padding: 0;}
        }
      }

      .fill{
        background: $color-white;
        color: $color-black;
      }
    }

    .buttons-section{
      display: flex;
      gap: 9px;
      padding-bottom: 15px;

      button{
        padding: 10px 0px;
        width: 50%;
      }
    }

    .description{
      margin-bottom: 20px;
      font-weight: $font-weight-normal;
      font-size: $font-size-medium;
      line-height: 130%;
    }

    .phases-navigate{
      display: flex;
      font-weight: $font-weight-normal;
      font-size: $font-size-medium;
      line-height: 130%;
      border-top: 1px solid $color-grey50;
      padding: 20px 0;

      .next{
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .phase-header {
    grid-row: 2;
    display: flex;
    align-items: center;
    padding: 0 18px;
    border-bottom: 1px solid $color-black;
    position: relative;
    height: 78px;
    top: 0;
    left: 0;
    width: $no-collapsed-width;
    z-index: 3;
    background: $color-grey10;

    .presence {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 5px;
      margin-right: 15px;

      & > div {
        color: $color-white;
        background: $color-grey40;
        border-color: $color-grey40;
        border-radius: 1000px;
        width: 26px;
        height: 26px;
        line-height: 217%;
        font-size: $font-size-small;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        margin-left: -5px;
        border: none;
        position: relative;

        .tooltip {
          display: none;
          position: absolute;
          z-index: 1;
          background: $color-grey70;
          color: $color-black;
          border-left-width: 3px;
          border-left-style: solid;
          border-color: inherit;
          border-radius: 2px;
          padding: 20px;
          text-transform: none;
          text-align: left;
          font-size: $font-size-medium;
          white-space: nowrap;
          line-height: 131%;
        }

        &:hover {

          .tooltip {
            display: block;
          }
        }

        $colors: (
          0: #d07017,
          1: #4c9645,
          2: #8e55c2,
          3: #149ca5,
          4: #e6bc25,
          5: #bb2158,
        );
        @each $id, $color in $colors {
          &.color#{$id} {
            background: $color;
            border-color: $color;

            &.idle {
              background: rgba($color-grey50, 0.3);
              border-color: rgba($color-grey50, 0.3);

              & > span:first-child {
                opacity: 0.3;
              }
            }

            &.editing {
              animation: shadow-pulse#{$id} 2s infinite;
              @keyframes shadow-pulse#{$id} {

                0% {
                  box-shadow: 0px 0px 0px 0px $color;
                }

                100% {
                  box-shadow: 0px 0px 0px 10px rgba($color-white, 0);
                }
              }
            }
          }
        }
      }
    }

    .title {
      padding: 12px 0px;
      overflow: hidden;

      h1,
      h2 {
        color: $color-grey70;
        font-size: $font-size-large;
        font-weight: $font-weight-normal;
        margin: 0;
        border: none;
        background: transparent;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          border-right: 1px solid $color-grey40;
          padding: 5px 15px 5px 0;
          margin-right: 15px;
        }
      }

      h1 {
        font-weight: $font-weight-bold;
      }
    }

    .add-products {
      width: 100%;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      position: relative;
      border: 1px solid $color-white;
      color:  $color-white;

      &:hover {
        background: $color-white;
        color: $color-grey15;
      }

      &-menu {
        position: absolute;
        width: inherit;

        &__menu {
          width: inherit;
          margin-top: 2px;
          padding: 10px 0;

          li {
            line-height: 140%;

            &:hover{

              &:not([role="presentation"]){
                background-color: $color-blue;
                color: $color-white;}
            }

            &[role="presentation"] {
              letter-spacing: 1px;
              margin-top: 14px;
              margin-bottom: 10px;
              font-size: $font-size-very-small;
              padding: 0 15px;

              &:first-child {
                margin-top: 10px;
              }

              &:not(:first-child) {
                margin-top: 30px;
              }
            }

            &[role="menuitem"] {
              padding: 10px 15px;
              font-size: $font-size-medium;
              height: 36px;
            }

            svg {
              flex-basis: 20px;
              margin-right: 3px;
            }
          }
        }
      }

      .text {
        font-size: $font-size-medium;
        line-height: 150%;
      }

      .menu-btn {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 14px;
      }

      &.open {
        border: 1px solid $color-grey70;
        color: $color-black;
        background: $color-grey70;

        .create-node {
          transform: rotate(-45deg);
        }
      }
    }
  }

  div.disconnect-overlay {
    position: absolute;
    // opacity: 0.3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;

    &.empty {
      min-height: 70px;
    }

    &.dragging {
      background: linear-gradient(0deg, rgba($color-red, 0.3), rgba($color-red, 0));
      border-bottom: 1px solid $color-white;
    }

    &.hover {
      background: rgba($color-red, 0.3);
      border: 1px solid $color-red;

      p {
        border-radius: 6px 6px 0 0;
        background-color: $color-red;
      }
    }

    p {
      margin: 0;
      color: $color-white;
      font-size: $font-size-small;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.5px;
      padding-right: 25px;
      padding-left: 10px;
      height: 30px;
      line-height: 150%;
      background-image: url("../../../static/img/icon-disconnect.svg");
      background-position: 95% center;
      background-repeat: no-repeat;
    }
  }

  .phase-nodes {
    grid-row: 3;
    overflow-y: auto;
    overflow-x: hidden;

    .phase-asps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $color-grey10;
      color: $color-white;
      padding: 15px 22px;
      cursor: pointer;
      margin-top: 0px;

      &.active {
        background: $color-white;
        color: $color-black;
      }

      .start {
        display: flex;
        align-items: center;
      }

      .asp-count {
        margin-left: 23px;
        display: flex;
        align-items: center;

        p {
          margin: 0;
          font-weight: $font-weight-thin;
          line-height: 0;
        }
      }

      .arrow-icon {
        justify-self: flex-end;
      }
    }

    .phase-recommend {
      transition: all .3s ease-in-out;
      max-height: 0;
      padding: 0;
      background: $color-grey10;
      border-bottom: 0;
      position: relative;

      &.visible{
        max-height: 100px;
        padding: 18px;
        border-bottom: 1px solid $color-black;
      }


      .loading {
        position: absolute;
        top: 26px;
        right: 40px;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: sub;
        background: url("../../../static/img/icon-progress-white.svg") center center no-repeat;
        animation: spin 0.6s infinite linear;
        @keyframes spin {

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .checkbox-container {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
      width: 0;

      .checkbox {
        background: $color-grey10;

        &.checked {
          background: $color-interactiveblue;
        }
      }
    }

    .bulk-actions {
      padding: 0 20px 0 0;
      color: $color-grey60;
      line-height: 286%;
      font-size: $font-size-medium;
      font-weight: $font-weight-light;
      display: flex;
      align-items: center;
      margin-top: -40px;
      transition: margin-top 200ms ease-in-out;
      position: relative;
      background: $color-grey20;
      width: $no-collapsed-width;
      border-bottom: 1px solid $color-black;
      z-index: 2;

      &.visible {
        margin-top: 0;
      }

      .checkbox-container {
        margin: 0;
        padding-left: 22px;
        padding-right: 33px;
      }

      .button {

        &.group {
          margin-left: auto;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        cursor: pointer;
        background: $color-grey10;
        color: $color-white;
        border: none;
        border-radius: 2px;
        padding: 7px 11px;
        font-size: $font-size-small;
        margin-left: 4px;
        line-height: 100%;
        height: 28px;
      }

      .move-menu {
        position: relative;
        top: -1px;

        .toggle.button {
          padding-right: 23px;
          background-image: url("../../../static/img/icon-dropdown-arrow.svg");
          background-repeat: no-repeat;
          background-position: right 10px top 10px;
        }

        .flyout-content {
          position: absolute;
          right: 0;
          display: none;
          width: 150px;
          padding: 0;
          margin: 0;
          background: $color-white;
          border-radius: 2px;
          -webkit-box-shadow: 0 0 10px rgba($color-black, 0.25);
          box-shadow: 0 0 10px rgba($color-black, 0.25);
          font-size: $font-size-medium;
          text-align: center;
          font-style: italic;
          color: $color-grey60;
          z-index: 1;

          button {
            padding: 5px 15px;
            font-size: $font-size-medium;
            width: 100%;
            cursor: pointer;
            border: none;
            outline: none;
            background: none;
            text-align: left;
            color: $color-black;

            &:hover {
              background-color: rgba($color-interactiveblue, 0.2);
            }

            &:active {
              background-color: rgba($color-interactiveblue, 0.4);
            }
          }
        }

        &.open {

          .toggle.button {
            background-color: $color-grey30;
          }

          .flyout-content {
            display: block;
          }
        }
      }
    }

    ul.UnconnectedList {
      position: relative;

      li {

        &.service {
          background-image: url("../../../static/img/icon-service-unconnected-default.svg");
        }

        &.selected {

          &.service {
            background-image: url("../../../static/img/icon-service-unconnected-selected.svg");
          }
        }

        &.checked {

          &.service {
            background-image: url("../../../static/img/icon-service-unconnected-checked.svg");
          }
        }
      }
    }

    div.hover-overlay {
      position: absolute;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background-color: rgba($color-interactiveblue, 0.4);
      border-radius: 0px;
      border: 1px solid $color-interactiveblue;
      transition: 0.1s opacity ease-in-out;
      overflow: hidden;
      pointer-events: none;

      p {
        display: block;
        height: 30px;
        line-height: 250%;
        width: 80px;
        padding-right: 16px;
        padding-left: 5px;
        border-radius: 0 0 6px 6px;
        margin: 0px auto 0;
        background: $color-interactiveblue url("../../../static/img/icon-move.svg") 89% center no-repeat;
        color: $color-white;
        text-transform: uppercase;
        font-size: $font-size-small;
        letter-spacing: 1px;
        transition: 0.1s margin-top linear;
      }

      &.group p {
        width: 145px;
        //   border-radius: 6px 6px 0 0;
        //   margin: -30px auto 0;
      }
    }

    ul:not([role="menu"]) {
      margin: 0;
      padding: 0;

      li:not([role="menuitem"]) {
        cursor: pointer;
        user-select: none;
        list-style: none;
        height: 40px;
        background: $color-grey15 no-repeat 31px center;
        color: $color-grey60;
        border-bottom: 1px solid $color-black;
        font-size: $font-size-medium;
        line-height: 286%;
        padding-left: 22px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        .item-text {
          margin-left: 30px;
        }

        &.editing {
          background-position: 61px;

          .item-text {
            margin-left: 60px;
            font-weight: $font-weight-light;
          }
        }


        &.office {
          background-image: url("../../../static/img/icon-office-default.svg");
        }

        &.datacenter {
          background-image: url("../../../static/img/icon-datacenter-default.svg");
        }

        &.group {
          background-image: url("../../../static/img/icon-group-default.svg");
          padding-right: 30px;

          .dot {
            position: absolute;
            right: 20px;
            width: 8px;
            height: 8px;
            margin-top: 16px;
            border-radius: 8px;
          }
        }

        &.service {
          color: $color-serviceorange;
          background-image: url("../../../static/img/icon-service-remote-default.svg");

          &.hidden {
            opacity: 0.5;
          }
        }

        &.service.local {
          background-image: url("../../../static/img/icon-service-local-default.svg");
        }

        &.selected {
          background-color: $color-white;
          color: $color-black;

          &.group {
            background-image: url("../../../static/img/icon-group-selected.svg");
          }

          &.office {
            background-image: url("../../../static/img/icon-office-selected.svg");
          }

          &.datacenter {
            background-image: url("../../../static/img/icon-datacenter-selected.svg");
          }

          &.service {
            background-color: $color-serviceorange;
            color: $color-white;
            background-image: url("../../../static/img/icon-service-remote-selected.svg");
          }

          &.service.local {
            background-image: url("../../../static/img/icon-service-local-selected.svg");
          }
        }

        &.checked {
          color: $color-interactiveblue;

          &.group {
            background-image: url("../../../static/img/icon-group-checked.svg");
          }

          &.office {
            background-image: url("../../../static/img/icon-office-checked.svg");
          }

          &.datacenter {
            background-image: url("../../../static/img/icon-datacenter-checked.svg");
          }

          &.service {
            color: $color-interactiveblue;
            background-image: url("../../../static/img/icon-service-remote-checked.svg");
          }

          &.service.local {
            background-image: url("../../../static/img/icon-service-local-checked.svg");
          }
        }

        &.removing {
          opacity: 0.2;
        }

        &.adding {

          .loading {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 30px;
            height: 30px;
            display: inline-block;
            vertical-align: sub;
            background: $color-grey10 url("../../../static/img/icon-progress.svg") center center no-repeat;
            box-shadow: 0px 0px 20px 5px $color-grey10;
            border-radius: 30px;
            animation: spin 0.6s infinite linear;
            @keyframes spin {

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }

      &.MetroList {

        > li:not([role="menuitem"]) {
          position: relative;
          padding: 0;
          height: auto;
          background: $color-grey15;
          z-index: 0;

          > div.metro {
            padding-top: 16px;
            padding-left: 60px;
            height: 84px;
            background: $color-grey20;
            color: $color-white;
            position: relative;

            &.underAcquisition {
              color: $color-darkorange;

              svg.metro-icon path {
                fill: $color-darkorange;
              }
            }
            // IMPORTANT:
            // Have .existing class LAST, so that it has priority
            // when multiple classes are applied.

            &.existing {
              color: $color-lightgreen;

              svg.metro-icon path {
                fill: $color-lightgreen;
              }
            }

            svg.metro-icon {
              position: absolute;
              top: 20px;
              left: 20px;
            }

            span.name {
              display: block;
              font-size: $font-size-large;
              line-height: 131%;
            }

            span.use-cases {
              font-size: $font-size-small;
              line-height: 150%;
              color: $color-grey60;
              display: inline-flex;
              align-items: center;

              .platform-tag {
                display: flex;
                align-items: center;
                margin-right: 5px;
                background: rgba($color-black, 0.2);
                border-radius: 2px;
                padding: 3px 6px;
                font-weight: $font-weight-normal;

                &.tag-enabled {
                  color: $color-white;
                  background: rgba($color-black, 0.4);
                }
              }

              .platform-tag-dot,
              .checked-icon {
                margin: 0 6px 0 4px;
              }
            }

            button {
              position: absolute;
              top: 6px;
              right: 20px;
              width: 26px;
              height: 26px;
              padding: 0;
              border: 1px solid rgba($color-black, 0.5);
              background: transparent;
              overflow: hidden;
              cursor: pointer;
            }

            button:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              background: transparent url("../../../static/img/icon-carat.svg") no-repeat center center;
              transform: rotate(180deg);
              transition: all 300ms ease-out;
            }

            button.checked:before {
              transform: rotate(0deg);
            }
          }

          > ul:not([role="menu"]) {
            position: relative;
            z-index: -1;
            transform: translateY(-300px);
            max-height: 0;
            opacity: 0;
            transition: all 300ms ease-in-out;

            &.expanded {
              opacity: 1;
              max-height: 300px;
              transform: translateY(0px);

              li:first-child:not([role="menuitem"]) {
                border-top: 1px solid $color-black;
              }

              li:last-child {
                border-bottom: none;
              }
            }
          }

          &.selected {

            > div.metro {
              background-color: $color-white;
              color: $color-black;

              &.underAcquisition {
                color: $color-orange;

                svg.metro-icon path {
                  fill: $color-orange;
                }
              }

              &.existing {
                color: $color-vividgreen;

                svg.metro-icon path {
                  fill: $color-vividgreen;
                }
              }

              svg.metro-icon path {
                fill: $color-black;
              }
            }

            button {
              background-color: $color-white;
            }

            .checked-icon,
            .platform-tag,
            .platform-tag-dot {
              color: $color-grey60;
              background: rgba($color-grey80, 0.2) !important;

              &.tag-enabled {
                color: black !important;
                background: rgba($color-grey80, 0.4) !important;
              }

              path {
                stroke: $color-grey20;
              }
            }
          }
        }
      }
    }

    /* Lower order class styles
      |
      | .empty-project { ... }
      | .child-node { ... }
      | .nodes { ... }
      |
    */
  }

  .phase-empty {
    grid-row: 3;
    background: url("../../../static/img/illo-empty-project.svg") no-repeat 50% 40%;
    color: $color-grey60;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    p {
      width: 250px;
      margin-top: 150px;
    }
  }

  .phase-tooltip {
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
    user-select: none;
    padding-left: 40px;
    padding-right: 20px;
    height: 130px;
    background: url("../../../static/img/bg-left-flyout.svg") no-repeat;
    display: none;

    &.open {
      display: block;
    }

    button.close {
      padding: 15px;
      position: absolute;
      top: 23px;
      right: 25px;
      border: none;
      width: 16px;
      height: 16px;
      background: url("../../../static/img/icon-close.svg") no-repeat center center;
      opacity: 0.4;
      cursor: pointer;
    }

    h3 {
      font-size: $font-size-heading-small;
      color: $color-red;
      font-weight: $font-weight-normal;
      margin: 25px 0 0 0;
    }

    p {
      margin: 0;
      font-size: $font-size-medium;
      color: $color-grey20;

      em {
        font-weight: $font-weight-bold;
        font-style: normal;
      }
    }
  }

  .phase-export {
    grid-row: 4;
    padding: 15px;
    border-top: 1px solid $color-black;
    bottom: 0;
    left: 0;
    width: $no-collapsed-width;
    background: $color-grey20;
  }

  /* Major Panel States
    |
    | 1. Generating Recommendations
    | 2. Loading Project
    |
  */
}

.flyout {
  position: absolute;
  top: 130px;
  left: 325px;
  z-index: 2;

  .flyout-content {
    display: none;
    position: absolute;
    right: -15px;
    width: 181px;
    padding: 5px 0 15px 0;
    margin: -10px -65px 30px 0;
    background: $color-white;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba($color-black, 0.25);
    color: $color-grey60;

    .phase-header-flyout-buttons {

      button {
        white-space: nowrap;
      }
    }

    button {
      padding: 5px 20px;
      width: 100%;
      cursor: pointer;
      border: none;
      background: none;
      color: $color-black;
      font-size: $font-size-medium;
      text-align: left;
      font-style: normal;
      line-height: 150%;
      font-weight: $font-weight-normal;

      &:hover {
        background-color: rgba($color-interactiveblue, 0.2);
      }
    }

    .title {
      padding: 15px 20px 10px 20px;
      margin: 0;
      font-style: normal;
      font-weight: $font-weight-normal;
      font-size: $font-size-very-small;
      line-height: 140%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $color-grey60;
    }

    .platform-equinix {
      padding-top: 10px;
    }
  }

  &.open {

    .flyout-content {
      display: block;
    }
  }
}
