@import "../../../../../theme.scss";

.EditGroupView {
  display: grid;
  grid-template-rows: 70px min-content min-content min-content auto 70px;
  height: 100%;

  >input {
    grid-row: 1;
    padding: 30px;
    padding-right: 70px;
    font-size: $font-size-heading-medium;
    font-weight: $font-weight-bold;
    font-family: "Roboto", helvetica, sans-serif;
    color: $color-black;
    border: none;
    border-bottom: 1px solid rgba($color-black, 0.2);
    background: transparent;
  }

  >input::placeholder {
    opacity: 0.2;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: $font-size-medium;
    color: $color-grey20;
  }

  .text {
    grid-row: 2;
    padding: 30px;
    padding-bottom: 20px;
  }

  .color {
    grid-row: 3;
    padding: 30px;
    padding-top: 20px;
    border-bottom: 1px solid rgba($color-black, 0.2);

    .list {
      display: flex;
      margin-top: 10px;
      margin-left: -5px;

      .item {
        margin: 5px;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        cursor: pointer;
        opacity: 0.7;

        &.selected {
          width: 18px;
          height: 18px;
          margin: 3px;
          border: 2px solid $color-white;
          box-shadow: 0px 0px 4px rgba($color-black, 0.7);
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      font-size: $font-size-medium;
      line-height: 129%;
      color: $color-interactiveblue;
      background: transparent;
      cursor: pointer;
      position: relative;

      .warning {
        background: $color-interactiveblue;
        color: $color-white;
        opacity: 0.5;
        margin-left: 5px;
        display: inline-block;
        width: 20px;
        line-height: 143%;
        border-radius: 10px;
      }

      .tooltip {
        display: none;
        position: absolute !important;
        // Make sure that tool tip is above the other position: absolute elements.
        z-index: 1;
        background: $color-grey40;
        color: $color-white;
        top: 30px;
        left: -20px;
        padding: 5px 15px;
        box-shadow: $color-grey60 1px 1px 5px;

        &:before {
          content: " ";
          border: 10px solid transparent;
          border-bottom-color: $color-grey40;
          width: 10px;
          position: absolute;
          top: -17px;
          right: 7px;
        }
      }

      &:hover {

        .tooltip {
          display: block;
        }
      }
    }
  }

  .buttons {
    grid-row: 6;
    height: 70px;
    position: fixed;
    bottom: 0px;
    background: $color-grey90;
  }
}
