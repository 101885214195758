@import "../../theme.scss";

.filter-input-container {
  display: flex;
  flex-basis: 1;
  flex-grow: 0;
  align-items: center;
  position: relative;
  background: $color-white;
  box-shadow: 0px 1px 3px rgba($color-black, 0.1);
  border-radius: 3px;
  width: 330px;
  height: 34px;

  @media only screen and (max-width: 899px) {
    width: 255px;
  }

  .magnifying-glass {
    margin: 0 12px;
  }

  .clear-search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 12px 0 8px;
  }

  input.filter-input {
    color: $color-grey20;
    font-weight: $font-weight-bold;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-size: $font-size-large;

    &::placeholder {
      font-weight: $font-weight-normal;
      color: $color-grey60;
      font-size: $font-size-medium;
    }
  }
}
