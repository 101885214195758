@import "../../../../theme.scss";

.phases-header {
  position: relative;
  z-index: 5;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  color: $color-black;
  width: 100%;
  padding: 0 22px;

  .loading {
    pointer-events: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: sub;
    background: url("../../../../static/img/icon-progress.svg") center center no-repeat;
    animation: spin .6s infinite linear;
    @keyframes spin {

      100% {
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);
      }
    }
  }


  .scroll-arrow {
    position: sticky;
    z-index: 5;
    background: $color-white;
    cursor: pointer;

    .scroll-arrow-content {
      position: relative;

      &::before, &::after {
        content: "";
        width: 25px;
        height: 140%;
        top: 50%;
        position: absolute;
      }
    }

    &.left {
      left: 0;

      .scroll-arrow-content::before {
        display: none;
      }

      .scroll-arrow-content::after {
        transform: translate(25px, -50%) matrix(-1, 0, 0, 1, 0, 0);
        right: 0;
        background: linear-gradient(270deg, $color-white 0%, rgba($color-white, 0) 100%);
      }
    }

    &.right {
      right: 0;

      .scroll-arrow-content::after {
        display: none;
      }

      .scroll-arrow-content::before {
        transform: translate(-25px, -50%);
        left: 0;
        background: linear-gradient(270deg, $color-white 0%, rgba($color-white, 0) 100%);
      }
    }

  }

  .phases-tabs {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 75%;

    .drag-indicator {
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translateX(-50%);
    }

    .button-group{
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      border-radius: 3px;
      height: 30px;
      margin-right: 5px;
      color: $color-grey60;

      .drag-indicator {
        display: none;
      }

      .triangle-container {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        svg path {
          fill: $color-grey60;
        }
      }

      &:hover {
        color: $color-grey20;
        background: $color-grey90;

        .drag-indicator {
          display: flex;
        }

        .triangle-container {
          background: $color-grey70;

          svg path {
            fill: $color-white;
          }

          &:hover {
            background: $color-grey20;
          }
        }
      }

      &.selected {
        color: $color-grey20;

        .triangle-container {

          svg path {
            fill: $color-grey20;
          }
        }

        &:hover {
          background: $color-grey20;
          color: $color-white;

          .triangle-container {
            background: $color-grey40;

            svg path{
              fill: $color-white;
            }
          }
        }
      }

      &.open {

        .triangle-container {
          background: $color-grey20;

          svg {
            -moz-transform:rotate(180deg);
            -o-transform:rotate(180deg);
            transform:rotate(180deg);

            path {
              fill: $color-white;
            }
          }
        }
      }

      &.disabled {
        color: $color-grey60;
        pointer-events: none;
      }
    }

    .phase-name {
      border: none;
      background: transparent;
      pointer-events: none;
      max-width: 170px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .add-phase {
      position: relative;
      white-space: nowrap;
      margin-left: 18px;
    }

  }

  .phase-menu-button {
    background: none;
    border: none;
    // font-size: 14px;
    // line-height: 15px;
    cursor: pointer;
    // margin: 0 20px 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-right: 5px;

    svg {
      // margin-left: 5px;
    }
  }

  .phases-toggle-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      font-size: $font-size-medium;
      color: $color-black;
      margin-right: 5px;
    }

    .section-item {
      display: flex;
      align-items: center;
    }

    .info-icon {
      margin-right: 15px;
      cursor: pointer;
    }

  }

  #multi-phases-information {

    span {
      font-weight: $font-weight-bold;
      font-size: $font-size-medium;
    }

    ul {
      margin: 4px 0;
      font-size: $font-size-medium;
      padding-left: 16px;
    }

    &::after { left: 83.8% !important; }
  }

  .editing-text{
    font-weight: $font-weight-normal;
    font-size: $font-size-medium;
    line-height: 130%;
    color: $color-grey15;
  }

  .clickable{
    cursor: pointer;
  }
}
