$color-white: #ffffff;
$color-grey90: #F5F5F5;
$color-grey80: #e4e4e4;
$color-grey70: #C4C4C4;
$color-grey60: #999999;
$color-grey50: #707073;
$color-grey40: #555555;
$color-grey30: #404040;
$color-grey20: #333333;
$color-grey15: #2b2b2b;
$color-grey10: #1e1e1e;
$color-black: #000000;
$color-red: #ED1C24;
$color-darkred: #D51118;
$color-maroon: #A8080E;
$color-blue: #006BD9;
$color-darkblue: #043776;
$color-waterblue: #B7E1FE;
$color-mapblue: #abd4e8;
$color-mapgreyblue: #c0ccd8;
$color-interactiveblue: #58B8E6;
$color-lightorange: #ffddc6;
$color-serviceorange: #FC9B29;
$color-orange: #eb7c2d;
$color-darkorange: #d86220;
$color-yellow: #FFBC16;
$color-green: #a3cf62;
$color-vividgreen: #17ae5c;
$color-darkgreen: #017B39;
$color-lightgreen: #8be0ad;

$font-size-smallText: 0.25rem; // 4px
$font-size-smallest: 0.438rem; // 7px
$font-size-very-small: 0.625rem; // 10px
$font-size-small: 0.75rem; // 12px
$font-size-medium: 0.875rem; // 14px
$font-size-large: 1rem; //16px
$font-size-heading-small: 1.125rem; //18px
$font-size-heading-medium: 1.25rem; // 20px
$font-size-heading-medium2: 1.6rem; // 25.6px
$font-size-heading-large: 1.875rem; //30px
$font-size-heading-extra-large: 2.6rem; //41.6px
$font-size-heading-largest: 3.6rem; //57.6px

$font-weight-ultra-bold: 1000;
$font-weight-extra-bold: 900;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-thin: 100;

:export {
  color-white:$color-white;
  color-grey90:$color-grey90;
  color-grey80:$color-grey80;
  color-grey70:$color-grey70;
  color-grey60:$color-grey60;
  color-grey50:$color-grey50;
  color-grey40:$color-grey40;
  color-grey30:$color-grey30;
  color-grey20:$color-grey20;
  color-grey15:$color-grey15;
  color-grey10:$color-grey10;
  color-black:$color-black;
  color-red:$color-red;
  color-darkred:$color-darkred;
  color-maroon:$color-maroon;
  color-interactiveblue:$color-interactiveblue;
  color-blue:$color-blue;
  color-darkblue:$color-darkblue;
  color-waterblue:$color-waterblue;
  color-mapblue:$color-mapblue;
  color-mapgreyblue:$color-mapgreyblue;
  color-lightorange:$color-lightorange;
  color-serviceorange:$color-serviceorange;
  color-orange:$color-orange;
  color-darkorange:$color-darkorange;
  color-yellow:$color-yellow;
  color-lightgreen:$color-lightgreen;
  color-green:$color-green;
  color-vividgreen:$color-vividgreen;
  color-darkgreen:$color-darkgreen;
  font-size-smallText: $font-size-smallText;
  font-size-smallest: $font-size-smallest;
  font-size-very-small: $font-size-very-small;
  font-size-small: $font-size-small;
  font-size-medium: $font-size-medium;
  font-size-large: $font-size-large;
  font-size-heading-small: $font-size-heading-small;
  font-size-heading-medium: $font-size-heading-medium;
  font-size-heading-medium2: $font-size-heading-medium2;
  font-size-heading-large: $font-size-heading-large;
  font-size-heading-extra-large: $font-size-heading-extra-large;
  font-size-heading-largest: $font-size-heading-largest;
  font-weight-ultra-bold: $font-weight-ultra-bold;
  font-weight-extra-bold: $font-weight-extra-bold;
  font-weight-bold:  $font-weight-bold;
  font-weight-semi-bold: $font-weight-semi-bold;
  font-weight-medium: $font-weight-medium;
  font-weight-normal: $font-weight-normal;
  font-weight-light: $font-weight-light;
  font-weight-thin: $font-weight-thin;
}