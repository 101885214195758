@import "../../../theme.scss";

.map {

  .leaflet-marker-icon.cluster {

    .marker {
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: $color-grey40;
      border: 1px solid $color-grey30;
      color: $color-white;
      border-radius: 100px;
      box-shadow: 0px 0.530303px 1.59091px rgba($color-black, 0.25);
      font-family: Proxima Nova, Helvetica, arial, sans-serif;
      font-size: $font-size-very-small;
      font-weight: $font-weight-bold;

      &:hover {
        background: $color-grey20;
        border: 1px solid $color-grey20;
      }

      &.metro {
        background-color: $color-red;
        border-color: $color-darkred;

        &:hover {
          background: $color-maroon;
          border-color: $color-maroon;
        }

        &.underAcquisition {
          background: $color-serviceorange;
          border-color: $color-orange;

          &:hover {
            background: $color-orange;
          }
        }
        // IMPORTANT:
        // Have .existing class LAST, so that it has priority
        // when multiple classes are applied.

        &.existing {
          background: $color-vividgreen;
          border-color: $color-darkgreen;

          &:hover {
            background: $color-darkgreen;
          }
        }
      }
    }

    .office, .datacenter {
      position: absolute;
      z-index: -1;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
