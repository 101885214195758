@import "../../../../../theme.scss";

.AddView.AddServiceView {

  h4 {
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: $font-size-large;
    line-height: 119%;
  }
}
